@mixin mobileFooterActive {
    position: fixed;
    bottom: 0;
    width: 100%;
    transform: translateY(0);
    transition-delay: .3s;
    background-color: $color-white;
}

.omnisearch-modal_portal {
    .omnisearch-modal_overlay {
        position: fixed;
        z-index: 100;
        background: none;
        transition: .1s ease-in-out;
    }

    .omnisearch-modal {
        outline: 0;
    }
}

.omnisearch_activities-popover {
    &:not(.omnisearch_activities-popover_selection-mismatch) {
        z-index: 1;
    }
    opacity: 0;
    animation: appear .3s ease-in-out forwards;
}

.omnisearch_content_input-area_guest-input_icon{
    path{
        stroke: #ACABB4;
    }
}

.omnisearch_header_embedded {
    flex: 1;

    .omnisearch_content {
        border: 1px solid $color-field-grey;
        box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
        border-radius: 34px;
        .omnisearch_content_input-group-area {
            // padding-top: 10px;
            // padding-bottom: 10px;
            @media (min-width: 1137px) and (max-width: 1380px) {
                .floating-input_input {
                    width: 55% !important;
                }
            }
            @media (min-width: 801px) and (max-width: 1080px) {
                .floating-input_input {
                    width: 55% !important;
                }
            }
            grid-template-columns: repeat(3, minmax(100px, 1fr)) auto;
            .omnisearch_content_input-area_guest-input {
                overflow: hidden;
            }
        }
    }
    button.omnisearch_input-wrapper_mobile_trigger {
        padding: 15px 20px;
        margin: 0;
        min-width: 240px;
        border: 1px solid #ACABB4;
        box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
        border-radius: 34px;
        width: calc(100vw - 80px);
    }
}
.omnisearch_content {
    &.omnisearch_content--desktop {
        .omnisearch_content_action-area_initial-step_around-current-location {
            opacity: 0;
            height: 0;
            margin-top: -2.5rem;
        }
        .omnisearch_content_input-group-area {
            &.omnisearch_content_input-group-area--valid {
                .omnisearch_content_search-action-area_btn {
                    .omnisearch_content_search-action-area_btn-label {
                        width: rem(50);
                        opacity: 1;
                    }
                }
            }
        }

        margin-left: rem(20);
        margin-right: rem(20);
        position: relative;
        // background-color: $color-white;
        // border-radius: rem(37);

        &.omnisearch_content_step-initial,
        &.omnisearch_content_step-interests {
            .omnisearch_content_action-area {
                width: 100%;
            }
        }

        &.omnisearch_content_step-location {
            .omnisearch_content_action-area {
                width: 65%;
                .omnisearch_content_action-area_destination-picker {
                    padding-left: rem(10);
                    padding-top: rem(20);
                    padding-bottom: rem(20);
                    margin: initial;
                }
            }
        }

        &.omnisearch_content_step-dates {
            .omnisearch_content_action-area {
                left: 50%;
                transform: translate(-50%, 10px);
            }
        }

        &.omnisearch_content_step-guests {
            .omnisearch_content_action-area {
                right: 0;
                .omnisearch_content_action-area_guest-picker {
                    padding: rem(20);
                    margin: initial;
                }
            }
        }

        &.omnisearch_content_step-interests {
            .omnisearch_content_action-area {
                .swiper-container {
                    padding-bottom: 12px;

                    .swiper-scrollbar {
                        height: 4px;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                    }

                    .swiper-wrapper {
                        .swiper-slide {
                            width: auto;
                        }
                    }
                }
            }
        }

        .omnisearch_content_search-action-area {
            margin: auto;
            margin-right: 5px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border-radius: 50%;
                background-color: transparent;
                transition: background-color 0.2s ease;
            }

            &:hover {
                &::before {
                    background-color: #f53959;
                }
            }

            .omnisearch_content_search-action-area_btn {
                position: relative;
                width: 100%;
                height: 100%;
                padding: 0;
                background: none;
                box-shadow: none;
                color:#F35597;
                &.omnisearch_content_search-action-area_btn--disabled {
                    cursor: initial;
                }
                .omnisearch_content_search-action-area_btn-icon {
                    width: 100%;
                    margin-right: initial;
                    path {
                        fill: $color-white;
                    }
                }
                .omnisearch_content_search-action-area_btn-label {
                    transition: .3s ease-in-out;
                    display: inline-block;
                    width: 0;
                    opacity: 0;
                    overflow: hidden;
                }
            }
        }
    }

    .omnisearch_content_head {
        position: relative;
        margin-top: rem(20);

        .omnisearch_content_logo {
            width: rem(150);

            .logo-purple {
                fill: #4c35bc;
            }

            .logo-pink {
                fill: #fa368b;
            }
        }

        .omnisearch_content_close {
            cursor: pointer;
            position: absolute;
            top: rem(30);
            right: 0;
            border: none;
            background: none;

            .omnisearch_content_close_icon {
                width: rem(12);
                height: rem(12);

                path {
                    stroke: $color-black;
                }
            }
        }
    }

    .omnisearch_content_input-group-area {
        margin-left: auto;
        margin-right: auto;
        // display: grid;
        //grid-template-columns: 250px 183px 170px max-content;
        // grid-template-columns: 302px 195px 220px max-content;
        // column-gap: rem(10);
        // padding: rem(12) rem(10) rem(12) rem(25);
        width: 100%;
        height: 66px;
        border: 2px solid #E9E9EB;
        // border: 2px solid red;
        border-radius: 37px;
        background-color: $color-white;
        display: flex;

        &:hover {
            cursor: pointer;
        }

        @media (min-width: 763px) and (max-width: 1136px) {
            // grid-template-columns: max-content 183px max-content max-content;
        }
    }

    .omnisearch_content_input-area_labelled-input-wrapper {
        position: relative;

        .omnisearch_content_input-area_labelled-input-wrapper_label {
            position: absolute;
            left: 0;
            top: rem(-5);
            opacity: 0;
            animation: appear .3s ease-in-out forwards;
            font-weight: 600;
            font-size: rem(12);
            line-height: rem(16);
            letter-spacing: 0.02em;
            color: $color-subtle-grey;
        }
    }
    .omnisearch_content_input-area_input_continer {
        .omnisearch_content_input-area_input_container--who {
            color: #242526;
            font-size: 12px;
            font-weight: 600;
            line-height: 150%; 
            letter-spacing: 0.12px;
        }
        .omnisearch_content_input-area_input_container--addguest {
            color: #797882;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 0.14px;
        }
        .omnisearch_content_input-area_input_container--dates {
            padding: 0 0.9375rem;
        }
    }
    // .omnisearch_content_input-area_input-slot--active {
    //     border-radius: 37px;
    //     background: #FFF;
    //     box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    // }
   
    .omnisearch_content_input-area_input-slot {
        height: 100%;
        display: grid;
        z-index: 1;
        grid-template-columns: max-content 1fr max-content;
        align-items: center;
        transition: .3s ease-out;
        transform: translateX(0);
        padding: rem(12) rem(10) rem(12) rem(25);
        // &:not(:last-child) {
        //     padding-right: rem(10);
        //     border-right: rem(1) solid $color-light-gray-input;
        //     padding-left: 0px;
        // }
        &:first-child {
            padding-left: 18px;
            cursor: pointer;
        }
        // &:hover {
        //     &:first-child {
        //         border-right: none;
        //     }
        //     background: #FDE9F1;
        //     border: none;
        //     border-radius: 2.3125rem;
        //     border-right: none;
        //     border-left: none;
        // }
        .omnisearch_content_input-area_input-slot_close-btn {
            animation: appear .3s ease-in-out forwards;
            opacity: 0;
            border: none;
            outline: none;
            display: flex;
            justify-content: center;
            align-items: center;
            right: rem(13);
            cursor: pointer;
            background-color: $color-light-gray-input;
            border-radius: 50%;
            width: rem(24);
            height: rem(24);
            margin-left: auto;
            .omnisearch_content_input-area_input-slot_close-btn_icon {
                width: rem(10);
                height: rem(10);

                path {
                    stroke: #797882;
                    stroke-width: rem(2);
                }
            }
        }

        &.omnisearch_content_input-area_destination-input {
            &:not(.omnisearch_content_input-area_input-slot--active) {
                .floating-input_input {
                    &.floating-input_input  {
                        color: $color-black;
                    }
                }
                input::placeholder{
                    color: #797882;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%;
                    letter-spacing: 0.14px;
                }
            }

            .floating-input_input {
                text-overflow: ellipsis;
                &.floating-input_input__focused {
                    border: none;
                }

                caret-color: $color-primary-hover;
                background: none;
                border: none;
                font-family: $primary-font;
                font-size: 14px;
                line-height: 1rem;
                font-weight: 600;
                height: 24px;
                cursor: pointer;
                &::placeholder {
                    color: #797882;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%;
                    letter-spacing: 0.14px;
                }
            }

            .omnisearch_content_input-area_labelled-input-wrapper_label {
                margin-left: rem(15);
            }
        }

        &.omnisearch_content_input-area_dates-input,
        &.omnisearch_content_input-area_guest-input {
            &.omnisearch_content_input-area_input-slot--active {
                .omnisearch_content_input-area_labelled-input-wrapper {
                    .omnisearch_content_input-area_input--dirty {
                        color: $color-black;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;

                        .omnisearch_content_input-area_dates-input_valid {
                            color: $color-black;
                        }
                    }
                }
            }

            .omnisearch_content_input-area_labelled-input-wrapper {
                margin-left: rem(17);
                color: $color-black;
                cursor: pointer;

                .omnisearch_content_input-area_labelled-input-wrapper_label {
                    top: rem(-16);
                }

                .omnisearch_content_input-area_input--empty {
                    font-weight: 600;
                }

                .omnisearch_content_input-area_input--dirty {
                    font-weight: 600;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-size: 14px;

                    .omnisearch_content_input-area_dates-input_valid {
                        font-weight: 600;
                        color: $color-black;
                    }
                }
            }
        }

        &.omnisearch_content_input-area_guest-input {
            border-right: none;
            white-space: nowrap;

            &.omnisearch_content_input-area_input-slot--active {
                .omnisearch_content_input-area_input-slot_close-btn {
                    display: none;
                }
                .omnisearch_content_input-area_labelled-input-wrapper {
                    .omnisearch_content_input-area_input--dirty {
                        color: $color-black;
                    }
                }
            }

            .omnisearch_content_input-area_labelled-input-wrapper {
                .omnisearch_content_input-area_input--dirty {
                    font-weight: 600;
                    color: $color-black;
                }
            }
        }
    }

    .omnisearch_content_action-area {
        position: absolute;
        transform: translateY(10px);
        background-color: $color-white;
        border-radius: rem(23);
        box-shadow: rgb(0 0 0 / 15%) 0px 16px 32px;
        overflow: hidden;
        opacity: 0;
        animation: appear .3s ease-in-out forwards;
    }
}

.omnisearch_content_search-action-area {
    margin-top: rem(20);
    margin-bottom: rem(20);
    margin-left: auto;
    margin-right: auto;
    max-width: rem(820);
    background: linear-gradient(92deg, #F35597 1.79%, #F53959 99.81%);
    border-radius: 50%;
    width: 56px;
    height: 56px;
    // display: grid;
    // place-items: center;

    .omnisearch_content_search-action-area_btn {
        white-space: nowrap;
        animation: appear .3s ease-in-out forwards;
        opacity: 0;
        padding: rem(15) rem(20);
        border: none;
        outline: 0;
        border-radius: rem(15);
        @media screen and (max-width: 600px) {
            min-width: 129px;
            border-radius: 35px;
            display: flex;
            justify-content: center;
        }
        background: linear-gradient(135deg, #F35597 0%, #7868C7 100%);
        // background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
        // box-shadow: 0 rem(4) rem(16) rgba(243, 85, 151, .3);
        font-family: $primary-font;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1rem;
        color: $color-white;
        display: flex;
        align-items: center;
        cursor: pointer;

        .omnisearch_content_search-action-area_btn-icon {
            margin-right: rem(10);
            path {
                fill: $color-white;
            }
        }
    }
}

.omnisearch_content_action-area_initial-step {
    padding: rem(20);

    .omnisearch_content_action-area_initial-step_slider-track {
        width: 100%;

        &:not(:first-child) {
            margin-top: rem(40);
        }

        .omnisearch_content_action-area_initial-step_slider-track_title {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.01em;
            color: #000000;
            margin-bottom: 9px;
        }

        .swiper-slide {
            width: fit-content;
        }

        .swiper-button-next,
        .swiper-button-prev {
            background: $color-white;
            box-shadow: 0 0 rem(40) rem(70) $color-white;
            transition: .3s ease-in-out;
            color: $color-black;
            outline: 0;

            &::after {
                font-weight: bold;
                font-size: initial;
                color:#797882;
            }

            &.swiper-button-disabled {
                opacity: 0;
                color: $color-black;
            }
        }

        .swiper-button-prev {
            transform: translateX(-12px);
        }

        .swiper-button-next {
            transform: translateX(12px);
        }

        .swiper-pagination {
            display: none;
        }
    }
}

.omnisearch_content_action-area_initial-step_slide-chip {
    border: 1px solid #DFE2E5;
    box-sizing: border-box;
    border-radius: 18px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    column-gap: rem(15);
    align-items: center;
    padding: 8px 16px;
    font-size: rem(14);
    line-height: rem(21);
    letter-spacing: 0.01em;
    color: #242526;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover{
        background: #FFEBE0;
        border: 1px solid #FFEBE0;
    }

    .omnisearch_content_action-area_initial-step_slide-chip_content {
        display: flex;
        align-items: center;
    }
    .omnisearch_content_action-area_initial-step_slide-chip_content_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: rem(10);
        display: none;
    }
    .omnisearch_content_action-area_initial-step_slide-chip_content-icon {
        transform: rotate(-90deg);
        width: 10px;
    }

    .omnisearch_content_action-area_initial-step_slide-chip_history-search {
        margin-top: rem(5);
        font-family: $primary-font;
        font-weight: 500;
        font-size: rem(12);
        line-height: rem(15);
        letter-spacing: 0.02em;
        color: $color-subtle-grey;
    }
}

.omnisearch_content_action-area_destination-picker {
    &.omnisearch_content_action-area_destination-picker--loading {
        max-height: rem(335);
    }

    display: flex;
    justify-content: center;
    margin-top: rem(15);
    margin-left: rem(5);
    margin-right: rem(5);
    max-height: rem(335);
    padding-right: rem(15);
    .simplebar-scrollbar{
        margin-top: 18px;
    }
    .simplebar-scrollbar::before {
        width: rem(4);
        background: $color-subtle-grey;
        margin-bottom: 35px;
    }
    .simplebar-scrollbar::after {
        margin-bottom: 35px;
    }

    .omnisearch_content_action-area_destination-picker_search-suggestion {
        padding-right: 12px;
        * {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
        }

        &.omnisearch_content_action-area_destination-picker_search-suggestion--loading {
            align-items: center;
            height: rem(57);
            margin-top: rem(5);
        }

        &:hover:not(.omnisearch_content_action-area_destination-picker_search-suggestion--loading) {
            background-color: $color-shade-gray;
        }

        display: grid;
        grid-template-columns: rem(50) 1fr min-content;
        height: rem(80);
        font-family: $primary-font;
        text-align: left;
        border-radius: rem(15);
        cursor: pointer;
        width: 100%;
        margin-bottom: 5px;
        align-items: center;
        .omnisearch_stars {
            .ic-icon {
                display: flex;
                width: unset;
                height: unset;
                margin-right: 4px;
                position: relative;
                svg {
                    width: unset;
                }
            }
            .ic-icon:last-child {
                margin-right: unset;
            }
        }

        .somnisearch_content_action-area_destination-picker_search-suggestion_icon {
            width: rem(26);
            height: rem(26);
            justify-self: center;
            align-self: center;
            rect {
                width: 2px;
                &:first-child {
                    width: 17px;
                }
            }
        }

        .omnisearch_content_action-area_destination-picker_search-suggestion_desc {

            padding-top: rem(10);
            padding-right: rem(20);
            line-height: rem(20);

            &.omnisearch_content_action-area_destination-picker_search-suggestion_desc--single {
                line-height: rem(48);
                padding-top: 0;
                padding-bottom: 0;
            }

            .omnisearch_content_action-area_destination-picker_search-suggestion_highlight {
                font-weight: 500;
            }

            .omnisearch_content_action-area_destination-picker_search-suggestion_secondary {
                font-size: rem(14);
                line-height: rem(20);
                color: $color-light-black;
                margin-top: 3px;
            }
            .no_adddres_sugesstion {
                margin-top: -10px;
            }
            .omnisearch_content_action-area_destination-picker_search-suggestion_secondary_total_count{
                font-size: rem(14);
                line-height: rem(20);
                color: $color-light-black;
            }
        }

        .carret-icon {
            transform: rotate(-90deg);
            width: rem(12);
            height: rem(12);
            align-self: center;
        }
    }
}

.omnisearch_content_action-area_date-range-picker {
    .date-picker-dates-omni {
        border-bottom: 1px solid var(--color-field-grey);

        .date-picker-dates-omni-inner {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        @media screen and (max-width: 1000px) {
            .date-picker-dates-omni-inner {
                height: 100%;
                width: 100%;
            }
        }

        @media screen and (max-width: 763px) {
            position: absolute;
            width: 285px;
            margin: 0 auto;
            top: 0;
            left: 0;
            right: 0;
            height: 92px;
            z-index: 1;
            border: none;
        }
    }

    .day-picker-clear {
        display: flex;
        justify-content: flex-end;
        padding: 0 30px 20px 0;

        .day-picker-clear-button {
            appearance: none;
            background-color: unset;
            padding: 0;
            border: none;
            color: var(--color-black);
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            line-height: 150%;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .DayPicker {
        background: #fff;
        margin: auto;
        box-shadow: none;

        /* Fucking Rehan and Jones */
        @media screen and (min-width: 601px) and (max-width: 715px) {
            width: initial !important;

            &>div>div {
                width: initial !important;
            }
        }

        .DayPicker_transitionContainer {
            // padding-bottom: 37px;
            box-sizing: content-box;

            /* Fucking Rehan and Jones */
            @media screen and (min-width: 601px) and (max-width: 715px) {
                width: initial !important;
            }
        }

        .DayPicker_weekHeader {

            /* Fucking Rehan and Jones */
            @media screen and (min-width: 601px) and (max-width: 715px) {
                left: initial !important;

                &:last-child {
                    left: 377px !important;
                }
            }
        }

        .DayPicker_weekHeaders {
            font-family: $primary-font;
            font-size: rem(14);
            text-align: center;
            // text-transform: uppercase;
            // font-variant: small-caps;
            color: $color-dark-grey;

            @media screen and (min-width: 601px) {
                text-transform: uppercase;
                font-variant: small-caps;
            }

            .DayPicker_weekHeader_ul {
                // @media screen and (max-width: 600px) {
                //     max-width: 342px;
                //     padding: 0 13px;
                //     margin: 0 auto;
                // }
                .DayPicker_weekHeader_li {

                    /* Fucking Rehan and Jones */
                    @media screen and (min-width: 601px) and (max-width: 715px) {
                        width: 50px !important;
                    }
                }
            }
        }

        .CalendarDay {
            font-family: $primary-font;
            font-weight: 500;
            font-size: rem(15);
            line-height: 150%;

            @media screen and (max-width: 600px) {
                font-size: 1rem;
                font-weight: 600;
            }

            /* Fucking Rehan and Jones */
            @media screen and (min-width: 601px) and (max-width: 715px) {
                width: 50px !important;
            }

            &.CalendarDay__default {
                // @media screen and (min-width: 601px){
                //     color: $color-black;
                // }

                // &.CalendarDay__selected_start {
                //     color: $color-white;
                // }

                // &.CalendarDay__selected,
                // &:hover:not(.CalendarDay__selected_span) {
                //     border-radius: 16px;
                //     //color: $color-white;
                // }

                // &.CalendarDay__blocked_out_of_range {
                //     color: $color-subtle-grey;

                //     &:hover {
                //         color: $color-subtle-grey;
                //     }
                // }

                // &.CalendarDay__today {
                //     color: $color-vibrant-pink;


                //     &:hover {
                //         color: $color-white;
                //         // color: $color-black;
                //     }
                // }
            }
        }

        .DayPickerNavigation_button {
            &.DayPickerNavigation_button__disabled {
                display: none;
            }
        }
    }
}

.omnisearch_content_action-area_guest-picker {
    margin-top: rem(20);
    display: grid;
    grid-template-rows: repeat(3, auto);
    row-gap: rem(22);
    justify-content: flex-end;
    user-select: none;

    .omnisearch_content_action-area_guest-picker_track {
        display: grid;
        // grid-template-columns: minmax(120px, 120px) max-content;
        grid-template-columns: 1fr auto;
        align-items: center;
        // max-width: rem(232);
        width: rem(260);
        border-bottom: 0.5px solid #E9E9EB;
        padding-bottom: rem(20);

        &.omnisearch_content_action-area_guest-picker_track--large {
            width: rem(442);
        }

        &:last-child{
            border-bottom: none;
            padding-bottom: 0;
        }

        .omnisearch_content_action-area_guest-picker_guest-type {
            font-weight: 600;
            font-family: $primary-font;
            font-size: rem(14);
            line-height: rem(21);
            color: $color-black;

            .omnisearch_content_action-area_guest-picker_guest-type_subtitle {
                font-weight: 400;
                color: $color-gray-font;
            }
            .omnisearch_content_action-area_guest-picker_guest-type_adultsubtitle {
                font-weight: 400;
                color: $color-gray-font;
                font-size: 15px;
                line-height: 20px;
                display: block;
            }
        }

        .omnisearch_content_action-area_guest-picker_guest-control {
            display: grid;
            grid-template-columns: max-content 1fr max-content;
            column-gap: rem(16);
            max-width: rem(100);
            align-items: center;
            justify-content: center;
            text-align: center;

            .omnisearch_content_action-area_guest-picker_guest-control_label {
                width: rem(40);
                line-height: rem(40);
                background: $color-shade-gray;
                border-radius: 50%;
                font-weight: 600;
            }

            .omnisearch_content_action-area_guest-picker_guest-control_action {
                &:disabled {
                    cursor: initial;

                    path {
                        stroke: $color-subtle-grey;
                    }

                    &+.omnisearch_content_action-area_guest-picker_guest-control_label {
                        background: $color-shade-gray;
                        color: $color-subtle-grey;
                    }
                }

                border: none;
                background: none;
                outline: 0;
                cursor: pointer;
                transform: rotate(-90deg);

                &:first-child {
                    transform: rotate(90deg);
                }
            }
        }

        &.omnisearch_content_action-area_guest-picker_track--extended {
            grid-template-rows: repeat(2, auto);
        }
    }
}

.auth-dialog-open {
    .omnisearch_input-wrapper {
        .omnisearch_input-wrapper_mobile {
            z-index: 2;
        }
    }
}

.omnisearch {
    .omnisearch_input-wrapper_mobile_trigger {
        border-radius: rem(30);
        // max-width: 25rem;
        border: rem(1) solid $color-subtle-grey;
        background-color: $color-white;
        width: 100%;
        min-height: 60px;
        height: 60px;
        justify-content: unset;
        padding-right: 1.5625rem;
        padding-left: 1.5625rem;
        font-family: Poppins;
        letter-spacing: 0.01em;
        //margin-top: rem(10);
        margin-top: mvw(30);
        display: flex;
        gap: 21px;
        line-height: 20px;
        @media screen and (max-width:357px)  {
            max-width: 240px;
            gap: 3px
        }
        .omnisearch_input-wrapper_mobile_trigger_container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .omnisearch_input-wrapper_mobile_trigger_title {
            font-size: 14px;
            font-weight: 600;
            color: $color-pure-black;
        }
        .omnisearch_input-wrapper_mobile_trigger_subtitle {
            color: $color-dark-grey;
            font-size: 12px;
            font-weight: 400;
        }
        .search-icon {
            width: 28px;
            height: 28px;
            path {
                fill: $color-primary;
            }
        }
    }
}

.omnisearch_input-wrapper {
    &.omnisearch_input-wrapper--desktop {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-width: rem(929);
        width: 100%;
        @media (min-width: 763px) and (max-width: 1136px) {
            top: 26%;
            padding: 0 30px;
            .omnisearch_content--desktop{
                margin: 0;
            }
            .omnisearch_content_input-group-area.omnisearch_content_input-group-area--valid{
                grid-template-columns: 1fr 1fr 1fr 100px;
            }
        }
    }

    &.guest-selector_modal {
        .guest-selector_modal-overlay {
            align-items: flex-start;
            z-index: 15;
            // height: 100vh;
            background-color: $color-white;

            // z-index: 5;

            .guest-selector_modal-content {
                display: block;
                height: 100%;
                background-color: $color-white;
                transform: translateY(100%);
            }
        }
    }

    .omnisearch_input-wrapper_mobile {

        .omnisearch_input-wrapper_mobile_action-area {
            animation: appear .3s ease-in-out .5s forwards;
            opacity: 0;

            .omnisearch_input-wrapper_mobile_action-area_scrollbar {
                max-height: calc(100vh - 100px);
                .simplebar-scrollbar:before {
                    width: rem(4);
                    background: $color-subtle-grey;
                }
            }
            .omnisearch_content_action-area_initial-step_ios {
                margin-bottom: 170px;
            }
            .omnisearch_content_action-area_initial-step {
                margin-top: 0;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 90px;
                .omnisearch_content_action-area_initial-step_slider-track{
                    width: calc(100% - 40px);
                    margin: 10px auto;
                    border: 0.5px solid #DFE2E5;
                    box-sizing: border-box;
                    box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
                    border-radius: 15px;
                    padding: rem(20);

                    &.omnisearch_content_action-area_initial-step_around-current-location {
                        border-radius: initial;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        background-color: transparent;
                        box-shadow: none;
                        padding: 0 0 rem(12) 0;
                        margin-bottom: rem(20);
                        .omnisearch_content_action-area_initial-step_around-current-location_action {
                            background-color: inherit;
                            outline: 0;
                            border: none;
                            display: flex;
                            align-items: center;
                            color: $color-black;
                            font-size: 14px;
                            font-weight: 600;
                            letter-spacing: 0.01em;
                            line-height: 150%;
                            font-family: $primary-font;
                            cursor: pointer;
                        }
                    }

                    .swiper-wrapper{
                        transform: none !important;
                        flex-direction: column;

                        .swiper-slide{
                            width: 100%;
                            margin-right: 0;

                            .omnisearch_content_action-area_initial-step_slide-chip{
                                border-radius: 0;
                                border: none;
                                padding: 20px 0 20px 0;
                                border-bottom: 0.5px solid #E9E9EB;

                                svg{
                                    display: none;
                                }

                                .omnisearch_content_action-area_initial-step_slide-chip_content_icon {
                                    display: flex;
                                    svg {
                                        display: initial;
                                    }
                                }

                                &:hover{
                                    background: none;
                                    border-bottom: 0.5px solid #E9E9EB;
                                }
                            }

                            &:first-child{
                                .omnisearch_content_action-area_initial-step_slide-chip{
                                    padding-top: 10px 0;
                                }
                            }
                            &:last-child{
                                .omnisearch_content_action-area_initial-step_slide-chip{
                                    padding-bottom: 0;
                                    border-bottom: none
                                }
                            }

                        }
                    }

                    /*&.os--nearby{
                        .swiper-wrapper{
                            .swiper-slide{
                                .omnisearch_content_action-area_initial-step_slide-chip{
                                    &::before{
                                        position: absolute;
                                        content: '';
                                        width: 24px;
                                        height: 24px;
                                        left: 0;
                                        background: url('/static/img/pin.svg') no-repeat center center;
                                        background-size: cover;
                                    }
                                }

                            }
                        }
                    }*/

                }


                .omnisearch_content_action-area_initial-step_slide-chip {
                    padding: rem(15);
                }
            }
        }

        .omnisearch_content_action-area_destination-picker {
            margin-top: rem(20);
            max-height: initial;
            padding-left: rem(15);

            &.omnisearch_content_action-area_destination-picker--loading {
                grid-template-columns: 3.125rem 1fr;
                // padding-right: 0;
            }
            .omnisearch_content_action-area_destination-picker_search-suggestion {
                &:last-child {
                    margin-bottom: rem(110);
                }
                .carret-icon {
                    margin-right: rem(10);
                }
            }
        }

        .omnisearch_input-wrapper_mobile_screen_dates-picker_head,
        .omnisearch_input-wrapper_mobile_screen_guest-picker_head,
        .omnisearch_input-wrapper_mobile_screen_interests-picker_head {
            font-family: $primary-font;
            font-weight: 600;
            font-size: 1rem;
            line-height: rem(20);
            color: $color-black;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .omnisearch_input-wrapper_mobile_screen_dates-picker_head_subtitle {
                line-height: 0;
                opacity: 0;
                transition: .3s ease-in-out;
                color: $color-subtle-grey;
                font-size: rem(13);
                letter-spacing: 0.02em;
                overflow: hidden;

                &.omnisearch_input-wrapper_mobile_screen_dates-picker_head_subtitle--active {
                    line-height: rem(20);
                    opacity: 1;
                }
            }
        }

        .omnisearch_input-wrapper_mobile_screen_guest-picker_content {
            margin-right: rem(20);
            margin-left: rem(20);
            .omnisearch_input-wrapper_mobile_screen_guest-picker_content_info-board {
                background-color: $color-shade-gray;
                border-radius: rem(15);
            }

            .omnisearch_input-wrapper_mobile_screen_guest-picker_content_info-board_row {
                &:not(:last-child) {
                    border-bottom: rem(1) solid rgba(172, 171, 180, .3);
                }
                &.omnisearch_input-wrapper_mobile_screen_guest-picker_content_info-board_row--active {
                    font-weight: 600;
                    path {
                        stroke: $color-black;
                    }
                }
                .omnisearch_input-wrapper_mobile_screen_guest-picker_content_info-board_row_location-icon {
                    position: relative;
                    left: -2px;
                }
                display: grid;
                grid-template-columns: rem(25) 1fr;
                column-gap: rem(10);
                align-items: center;
                padding-left: rem(16);
                padding-right: rem(16);
                line-height: 50px;
                * {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .filters-guest-selector_mobile-icon {
                    path {
                        stroke: unset;
                        fill: $color-subtle-grey;
                    }
                }

            }
        }

        .omnisearch_content_action-area_date-range-picker {
            .DayPicker_weekHeader:last-child {

                // fucking Rehan and Jones
                @media screen and (max-width: 715px) and (min-width: 601px) {
                    left: initial !important;
                }
            }


            .DayPicker_weekHeaders {
                margin-bottom: rem(14);
                @media screen and (min-width:763px)  {
                    & {
                        margin-bottom: 0px;
                    }
                }
            }

            .DayPicker__verticalScrollable {
                .DayPicker_transitionContainer {
                    &.DayPicker_transitionContainer__verticalScrollable {
                        height: calc(100vh - 92px - 87px);
                    }

                    box-sizing: border-box;
                    padding-bottom: initial;

                    .DayPickerNavigation__vertical {
                        display: none;
                    }
                }
            }
        }

        .omnisearch_content_action-area_guest-picker {
            margin-top: rem(40);
            padding-bottom: rem(95);
            justify-content: flex-start;
            display: block;
            .omnisearch_content_action-area_guest-picker_track {
                margin-bottom: rem(22);
                max-width: initial;
                grid-template-columns: 1fr max-content;
                width: 100%;
                &.omnisearch_content_action-area_guest-picker_track--large {
                    width: 100%;
                }
            }
        }

        .omnisearch_input-wrapper_mobile_screen_destination-picker_next-btn,
        .omnisearch_input-wrapper_mobile_screen_dates-picker_next-btn,
        .omnisearch_input-wrapper_mobile_screen_guest-picker_next-btn {
            width: auto;
            height: auto;
            .omnisearch_content_search-action-area_btn {
                column-gap: 0;
                text-align: center;
            }
        }

        .omnisearch_input-wrapper_mobile_screen {
            height: 100%;
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            z-index: -1;
            overflow: hidden;
            background-color: rgba(0, 0, 0, 0);
            transition: background-color .3s ease-out .1s;

            &.omnisearch_input-wrapper_mobile_screen--open {
                z-index: 4;
                background-color: rgba(0, 0, 0, .3);
                transition-delay: 0s;

                .omnisearch_input-wrapper_mobile_screen_main {
                    transform: translateX(0);
                    transition-delay: .1s;
                }

                .omnisearch_input-wrapper_mobile_screen_footer {
                    @include mobileFooterActive;
                }
            }

            .omnisearch_input-wrapper_mobile_screen_main {
                height: 100%;
                background-color: $color-white;
                transform: translateX(110%);
                transition: .3s ease-out;

                .omnisearch_input-wrapper_mobile_screen_header {
                    display: grid;
                    grid-template-columns: max-content 1fr max-content;
                    padding-left: rem(30);
                    padding-right: rem(30);
                    background-color: $color-white;

                    .omnisearch_input-wrapper_mobile_screen_header_goback-btn,
                    .omnisearch_input-wrapper_mobile_screen_header_close-btn {
                        margin-top: rem(36);
                        margin-bottom: rem(36);

                        svg {
                            width: rem(12);
                            height: rem(12);

                            path {
                                stroke: $color-black;
                            }
                        }
                    }

                    .omnisearch_input-wrapper_mobile_screen_header_goback-btn {
                        svg {
                            transform: rotate(90deg);
                        }
                    }
                }

                .omnisearch_input-wrapper_mobile_screen_content {
                    max-height: calc(100vh - 87px - 92px);
                    height: 100%;
                    overflow: auto;
                    background-color: $color-white;
                }
            }

            // .omnisearch_input-wrapper_mobile_screen_footer {
            //     display: flex;
            //     justify-content: space-between;
            //     align-items: center;
            //     border-top: rem(1) solid $color-light-gray-input;
            //     transform: translateY(100%);
            //     transition: .3s ease-out;

            //     .omnisearch_content_search-action-area {
            //         width: auto;
            //         height: auto;
            //         margin-left: initial;
            //         margin-right: rem(20);
            //         display: table;
            //         .omnisearch_content_search-action-area_btn {
            //             position: relative;
            //             .omnisearch_content_search-action-area_btn-label {
            //                 padding-left: 35px;
            //             }
            //             .omnisearch_content_search-action-area_btn-icon {
            //                 position: absolute;
            //                 top: 10px;
            //                 left: 20px;
            //             }
            //         }
            //     }

            //     .omnisearch_input-wrapper_mobile_screen_footer_clear-btn {
            //         margin-left: rem(20);
            //     }
            // }

            .omnisearch_input-wrapper_mobile_screen_header_goback-btn,
            .omnisearch_input-wrapper_mobile_screen_header_close-btn,
            .omnisearch_input-wrapper_mobile_screen_footer_clear-btn {
                background: none;
                border: none;
                font-family: $primary-font;
                color: $color-black;
                font-weight: 600;
                font-size: 1rem;
                line-height: 1rem;
                // font-size: 1rem;
                // line-height: rem(20);
                outline: 0;
            }
        }

        .omnisearch_input-wrapper_mobile_screen_footer {
            &.omnisearch_input-wrapper_mobile_destination-footer {
                @include mobileFooterActive;
            }

            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: rem(1) solid $color-light-gray-input;
            transform: translateY(100%);
            transition: .3s ease-out;

            .omnisearch_content_search-action-area {
                width: auto;
                height: auto;
                margin-left: initial;
                margin-right: rem(20);
                display: table;
                .omnisearch_content_search-action-area_btn {
                    position: relative;
                    .omnisearch_content_search-action-area_btn-label {
                        padding-left: 35px;
                    }
                    .omnisearch_content_search-action-area_btn-icon {
                        position: absolute;
                        top: 10px;
                        left: 20px;
                    }
                    @media screen and (max-width:600px)  {
                        .omnisearch_content_search-action-area_btn-label {
                            padding-left: unset;
                        }
                        .omnisearch_content_search-action-area_btn-icon { 
                            display: none;
                        }
                    }
                }
            }

            .omnisearch_input-wrapper_mobile_screen_footer_clear-btn {
                margin-left: rem(20);
            }
        }
    }

    .omnisearch_input-wrapper_mobile_input-container {
        padding: rem(20);
        box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.06);

        .floating-input {
            height: auto;
            border-radius: rem(16);
            background-color: $color-white;

            .floating-input_input {
                border-radius: inherit;
                height: rem(50);
                padding-right: rem(40);
                padding-left: rem(25);
                color: #ACABB4;
                font-family: $primary-font;
                font-weight: 600;
                font-size: 1rem;
                background-color: $color-white;

                &::placeholder {
                    color: inherit;
                    font-family: inherit;
                    font-weight: inherit;
                    font-size: inherit;
                }
            }

            margin: auto;

            .search-icon {
                position: absolute;
                right: rem(17);
                top: 50%;
                transform: translateY(-50%);

                path {
                    fill: $color-primary;
                }
            }
        }

        .omnisearch_input-wrapper_mobile_input-container_main-input-wrapper {
            display: grid;
            grid-template-columns: 10px 83%;
            column-gap: rem(21);
            align-items: center;
            opacity: 0;
            animation: appear .3s ease-in-out forwards;

            .omnisearch_input-wrapper_mobile_input-container_main-input-wrapper_input {
                overflow: hidden;

                .omnisearch_input-wrapper_mobile_input-container_main-input-wrapper_input_prepend-icon {
                    position: absolute;
                    left: rem(19);
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 1;
                    path{
                        fill:#ACABB4;
                    }
                }

                .floating-input_input {
                    border-radius: rem(30);
                    padding-left: rem(45);
                    padding-right: rem(40);
                    caret-color: $color-primary-hover;
                    background-color: $color-shade-gray;
                    color: $color-black;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.01em;
                    color: #242526;

                    &.floating-input_input__focused {
                        border: none;
                    }

                    &::placeholder {
                        color: $color-subtle-grey;
                        font-family: inherit;
                        font-weight: normal;
                        font-size: inherit;
                    }
                }

                .floating-input_input_clear-btn {
                    position: absolute;
                    right: rem(12);
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: rgba(172, 171, 180, .3);
                }
            }

            .omnisearch_input-wrapper_mobile_input-container_main-input-wrapper_close-btn {
                background: none;
                border: none;
                color: $color-black;
                font-family: $primary-font;
                font-size: 1rem;
                line-height: rem(20);
                outline: 0;
                cursor: pointer;
            }
        }
    }
}
